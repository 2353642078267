<template>
  <div class="base-box">
    <slot>Base box</slot>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
.base-box {
  border: 1px solid var(--very-light-pink-battleship-grey);
  width: 100%;
}
</style>
