<template>
  <b-container>
    <h2>{{ $t('profile.settings.title') }}</h2>

    <h3>{{ $t('profile.settings.notification_settings') }}</h3>

    <base-box class="box mt-0">
      <b-row>
        <b-col cols="8">
          <h4 v-b-toggle.collapse-primary class="collapse-btn">
            {{ $t('profile.settings.primary_analysis') }}
            <i class="fas fa-chevron-up collapse-icon"></i>
          </h4>
        </b-col>

        <b-col cols="4" class="text-right">
          <toggle-button
            v-model="current_user.email_notifications.primary_analysis.all"
            :width=51
            :height=31
            :sync="true"
            :color="{checked: '#4cd964'}"
            @change="updateAllPrimaryAnalysisStatuses()"
          />
        </b-col>
      </b-row>
    </base-box>

    <b-collapse id="collapse-primary" class="collapse-content">
      <div
        v-for="market in subscribed_markets"
        :key="market.id"
      >
        <base-box
          class="box mt-0"
          v-if="!market.disable_charts"
        >

          <b-row>
            <b-col cols="8">
              <h4 v-b-toggle="'collapse-market-' + market.id" class="collapse-btn">
                {{ market.name }}
                <i class="fas fa-chevron-up collapse-icon"></i>
              </h4>
            </b-col>

            <b-col cols="4" class="text-right">
              <toggle-button
                v-model="current_user.email_notifications.primary_analysis.markets[market.id]"
                :sync="true"
                :width=51
                :height=31
                :color="{checked: '#4cd964'}"
                @change="updateAllMarketStatuses(market.id)"
              />
            </b-col>
          </b-row>
        </base-box>

        <b-collapse :id="'collapse-market-' + market.id" class="collapse-content nested">
          <base-box
            v-for="instrument in market.instruments"
            :key="instrument.id"
            class="box mt-0"
          >

            <b-row>
              <b-col cols="8">
                <h4 class="collapse-btn">
                  {{ instrument.name }}
                </h4>
              </b-col>

              <b-col cols="4" class="text-right">
                <toggle-button
                  v-model="current_user.email_notifications.primary_analysis.instruments[instrument.id]"
                  :sync="true"
                  :width=51
                  :height=31
                  :color="{checked: '#4cd964'}"
                  @change="updateSettings()"
                />
              </b-col>
            </b-row>
          </base-box>
        </b-collapse>
      </div>
    </b-collapse>

    <base-box class="box mt-0">
      <b-row>
        <b-col cols="8">
          <h4 v-b-toggle.collapse-intraday-updates class="collapse-btn">
            {{ $t('profile.settings.intraday_updates') }}
            <i class="fas fa-chevron-up collapse-icon"></i>
          </h4>
        </b-col>

        <b-col cols="4" class="text-right">
          <toggle-button
            v-model="current_user.email_notifications.intraday_updates.all"
            :width=51
            :height=31
            :sync="true"
            :color="{checked: '#4cd964'}"
            @change="updateAllIntradayUpdatesStatuses()"
          />
        </b-col>
      </b-row>
    </base-box>

    <b-collapse id="collapse-intraday-updates" class="collapse-content" v-model="visible">
      <div
        v-for="market in subscribed_markets"
        :key="market.id"
      >
        <base-box
          class="box mt-0"
          v-if="!market.disable_intraday_updates"
        >

          <b-row>
            <b-col cols="8">
              <h4 class="collapse-btn">
                {{ market.name }}
              </h4>
            </b-col>

            <b-col cols="4" class="text-right">
              <toggle-button
                v-model="current_user.email_notifications.intraday_updates.markets[market.id]"
                :sync="true"
                :width=51
                :height=31
                :color="{checked: '#4cd964'}"
                @change="updateSettings()"
              />
            </b-col>
          </b-row>
        </base-box>

      </div>
    </b-collapse>

    <base-box class="box mt-0">
      <b-row>
        <b-col cols="8">
          <h4 v-b-toggle.collapse-trading-notes class="collapse-btn">
            {{ $t('profile.settings.trading_notes') }}
            <i class="fas fa-chevron-up collapse-icon"></i>
          </h4>
        </b-col>

        <b-col cols="4" class="text-right">
          <toggle-button
            v-model="current_user.email_notifications.trading_notes.all"
            :width=51
            :height=31
            :sync="true"
            :color="{checked: '#4cd964'}"
            @change="updateAllTradingNotesStatuses()"
          />
        </b-col>
      </b-row>
    </base-box>

    <b-collapse id="collapse-trading-notes" class="collapse-content" v-model="visible2">
      <div
        v-for="market in subscribed_markets"
        :key="market.id"
      >
        <base-box
          class="box mt-0"
          v-if="!market.disable_charts"
        >

          <b-row>
            <b-col cols="8">
              <h4 class="collapse-btn">
                {{ market.name }}
              </h4>
            </b-col>

            <b-col cols="4" class="text-right">
              <toggle-button
                v-model="current_user.email_notifications.trading_notes.markets[market.id]"
                :sync="true"
                :width=51
                :height=31
                :color="{checked: '#4cd964'}"
                @change="updateSettings()"
              />
            </b-col>
          </b-row>
        </base-box>

      </div>
    </b-collapse>

  </b-container>
</template>

<script>
import BaseBox from '../../components/BaseBox.vue'
import { ToggleButton } from 'vue-js-toggle-button'
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      visible: true,
      visible2: true
    }
  },
  computed: {
    ...mapState({
      current_user: state => state.profile.account.current_user,
      markets: state => state.market.markets
    }),
    ...mapGetters({
      marketIds: 'market/marketIds',
      instrumentIds: 'market/instrumentIds',
      marketInstrumentIds: 'market/marketInstrumentIds'
    }),
    subscribed_markets () {
      const subscribed_market_ids = [].concat(...this.current_user.subscriptions.map(s => { return s.markets.map(m => { return m.id }) }))

      return this.markets.filter(m => subscribed_market_ids.includes(m.id))
    }
  },
  components: {
    BaseBox,
    ToggleButton
  },
  methods: {
    updateAllPrimaryAnalysisStatuses () {
      const status = this.current_user.email_notifications.primary_analysis.all

      this.marketIds.forEach((id, index) => {
        this.$set(this.current_user.email_notifications.primary_analysis.markets, id, status)
      })

      this.instrumentIds.forEach((id, index) => {
        this.$set(this.current_user.email_notifications.primary_analysis.instruments, id, status)
      })

      this.updateSettings()
    },
    updateAllIntradayUpdatesStatuses () {
      const status = this.current_user.email_notifications.intraday_updates.all

      this.marketIds.forEach((id, index) => {
        this.$set(this.current_user.email_notifications.intraday_updates.markets, id, status)
      })

      this.updateSettings()
    },
    updateAllTradingNotesStatuses () {
      const status = this.current_user.email_notifications.trading_notes.all

      this.marketIds.forEach((id, index) => {
        this.$set(this.current_user.email_notifications.trading_notes.markets, id, status)
      })

      this.updateSettings()
    },
    updateAllMarketStatuses (market_id) {
      const status = this.current_user.email_notifications.primary_analysis.markets[market_id]

      this.marketInstrumentIds(market_id).forEach((id, index) => {
        this.$set(this.current_user.email_notifications.primary_analysis.instruments, id, status)
      })

      this.updateSettings()
    },
    async updateSettings () {
      this.$store.dispatch('profile/account/updateSettings', {
        current_user: this.current_user
      }).then(() => {
        this.$notify({
          type: 'success',
          text: this.$t('profile.settings.updated')
        })
      })
    }
  },
  mounted: function () {
    this.$store.dispatch('market/loadMarkets').then(response => {
      this.marketIds.forEach((id, index) => {
        if (!Object.prototype.hasOwnProperty.call(this.current_user.email_notifications.primary_analysis.markets, id)) {
          this.$set(this.current_user.email_notifications.primary_analysis.markets, id, false)
        }

        if (!Object.prototype.hasOwnProperty.call(this.current_user.email_notifications.intraday_updates.markets, id)) {
          this.$set(this.current_user.email_notifications.intraday_updates.markets, id, false)
        }

        if (!Object.prototype.hasOwnProperty.call(this.current_user.email_notifications.trading_notes.markets, id)) {
          this.$set(this.current_user.email_notifications.trading_notes.markets, id, false)
        }
      })

      this.instrumentIds.forEach((id, index) => {
        if (!Object.prototype.hasOwnProperty.call(this.current_user.email_notifications.primary_analysis.instruments, id)) {
          this.$set(this.current_user.email_notifications.primary_analysis.instruments, id, false)
        }
      })
    })
  }
}
</script>

<style scoped lang="scss">
.container {
  max-width: 780px;
}

h2 {
  font-size: 26px;
  text-align: center;
  color: var(--charcoal-grey-white);
  font-weight: normal;
  margin: 45px 0 56px 0;
}

h3 {
  font-family: Heebo, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: var(--dark-grey-white);
  margin-bottom: 20px;
}

h4 {
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-grey-white);
  margin-bottom: 0;
  line-height: 31px;

  &.collapse-btn {
    cursor: pointer;
  }
}

.box {
  margin-top: 30px;
  padding: 20px 20px;
  border-left: 0;
  border-right: 0;
}

.collapse-icon {
  margin-left: 7px;
  color: var(--cool-grey);
}

.collapsed > .collapse-icon::before {
  content: "\f078";
}

.collapse-content {
  .box {
    padding-left: 30px;
  }

  &.nested {
    .box {
      padding-left: 40px;
    }
  }
}
</style>
